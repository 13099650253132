import {
	DetailedHTMLProps,
	ImgHTMLAttributes,
	useEffect,
	useState,
} from 'react';
import { FiCameraOff } from 'react-icons/fi';
import { LiaSpinnerSolid } from 'react-icons/lia';

import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

// import { BarapiLogo } from '../BarapiLogo';

export interface imageProps {
	src: string;
	srcset?: string;
	sizes?: string;
	width: number;
	height: number;
	alt: string;
	max: {
		src: string;
		width: number;
		height: number;
	};
}

interface imagePropsComponent
	extends DetailedHTMLProps<
		ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	> {
	typeLoading?: 'skeleton' | 'bar';
	activeZoom?: boolean;
}

export function Image({
	className,
	typeLoading = 'skeleton',
	src,
	activeZoom = false,
	...props
}: imagePropsComponent) {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!src) {
			setError(true);
			setLoading(false);
		}
	}, []);

	const classImage = cva(
		'w-auto transition-all data-[isload=false]:opacity-0 data-[isload=true]:opacity-100',
		{
			variants: {
				activeZoom: {
					false: 'h-full object-cover',
				},
			},
		},
	);

	const classContainer = twMerge(
		'overflow-hidden relative flex justify-center',
		className,
	);

	return (
		<div className={classContainer}>
			{loading && !error && (
				<span className="absolute -top-1 left-0 flex h-full w-full items-center justify-center">
					<LoadingIndicator type={typeLoading} />
				</span>
			)}

			{src && !error && (
				<img
					data-isload={!loading}
					className={classImage({ activeZoom })}
					{...props}
					src={src}
					decoding="async"
					loading="lazy"
					onError={() => setError(true)}
					onLoad={() => {
						setLoading(false);
					}}
				/>
			)}
			{error && (
				<div className="absolute left-1/2 top-1/2 flex h-full w-3/4 -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2 text-primary opacity-60">
					<FiCameraOff size={48} className="" />
				</div>
			)}
			<div className="absolute left-0 top-0 h-full w-full" />
		</div>
	);
}

function LoadingIndicator({
	type,
}: {
	type: imagePropsComponent['typeLoading'];
}) {
	switch (type) {
		case 'skeleton':
			return <div className="skeleton h-full w-full" />;
		case 'bar':
			return (
				<LiaSpinnerSolid size={56} className="animate-spin text-primary" />
			);

		default:
			return null;
	}
}
