import { useEffect, useState } from 'react';
import { LiaSpinnerSolid } from 'react-icons/lia';
import { MdDownload } from 'react-icons/md';

import { Button } from './ui/button';

export function WebAppInstallButton() {
	const [showInstallButton, setShowInstallButton] = useState(false);
	const [loading, setLoading] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

		window.addEventListener('appinstalled', async () => {
			setShowInstallButton(false);
		});

		return () => {
			window.removeEventListener(
				'beforeinstallprompt',
				handleBeforeInstallPrompt,
			);
		};
	}, []);

	const handleBeforeInstallPrompt = (event: any) => {
		event.preventDefault();

		if (window.matchMedia('(display-mode: standalone)').matches) {
			setShowInstallButton(false);
		} else {
			setShowInstallButton(true);
			setDeferredPrompt(event);
		}
	};

	const handleInstallClick = () => {
		setLoading(true);
		if (deferredPrompt) {
			deferredPrompt.prompt();

			deferredPrompt.userChoice.then(() => {
				setLoading(false);
			});
		}
	};

	return (
		showInstallButton && (
			<Button
				disabled={loading}
				variant={'outline'}
				onClick={handleInstallClick}
			>
				{loading ? (
					<LiaSpinnerSolid className="animate-spin" size={18} />
				) : (
					<MdDownload size={18} />
				)}
				{/* {loading ? 'Instalando...' : 'Instalar'} */}
			</Button>
		)
	);
}
