import { createRoot } from 'react-dom/client';

import { App } from './App.tsx';
import { AppProvider } from './context/app';
import { SocketIOProvider } from './context/socket';
import './index.css';

createRoot(document.getElementById('root')!).render(
	// <StrictMode>
	<AppProvider>
		<SocketIOProvider>
			<App />
		</SocketIOProvider>
	</AppProvider>,
	// </StrictMode>,
);
