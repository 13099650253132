import {
	createContext,
	useContext,
	useEffect,
	useLayoutEffect,
	useState,
} from 'react';

import { Manager, Socket } from 'socket.io-client';
import { toast } from 'sonner';

import { useApp } from './app';

type statusSocket =
	| 'connecting'
	| 'connected'
	| 'disconnected'
	| 'reconnected'
	| 'business_not_found'
	| 'token_invalid'
	| 'agent_not_found'
	| 'limit_single_connection';

interface SocketIOProps {
	updateStatusSocket: (statusSocket: statusSocket) => void;
	statusSocket: statusSocket;
	socket: Socket;
}

const socketIOContext = createContext<SocketIOProps>({} as any);

const uri = 'https://api.etechseabra.com.br';
const path = '/pdvhub/agent';

// const uri = 'http://192.168.1.88:3100';
// const path = '/socket';

const manager = new Manager(uri, {
	path,
	secure: true,
	autoConnect: false,
	extraHeaders: {
		'x-service': 'client',
	},
});

const socket = manager.socket('/');

export function SocketIOProvider({ children }: { children: React.ReactNode }) {
	const {
		auth,
		updateTypeConnection,
		updateToken,
		updateBusiness,
		updateAuthId,
	} = useApp();
	const [statusSocket, setStatusSocket] =
		useState<statusSocket>('disconnected');
	const statusErrorNoReset = ['agent_not_found'];
	const statusErrorReset = [
		'business_not_found',
		'token_invalid',
		'limit_single_connection',
	];

	useEffect(() => {
		if ([...statusErrorNoReset, ...statusErrorReset].includes(statusSocket)) {
			console.log(socket);

			socket.disconnect();
		}
	}, [statusSocket]);

	useLayoutEffect(() => {
		socket.auth = auth;
		if (auth.token) {
			const id = localStorage.getItem('authId');
			if (id) {
				socket.auth.id = id;
			}
			setStatusSocket('connecting');
			socket.connect();
		} else {
			if (socket.connected) {
				socket.disconnect();
			}
		}
		socket.on('connect', () => {});

		socket.on('error', (data) => {
			if (statusErrorReset.includes(data.code)) {
				if (['token_invalid', 'limit_single_connection'].includes(data.code)) {
					updateToken();
					toast.error(data.message);
				}
			}
			if (data.code === 'agent_not_found') {
				updateTypeConnection(data.data.typeConnection);
				updateBusiness(data.data);
			}
			updateStatusSocket(data.code);
			toast.error(data.message);
		});

		socket.on('connected', (data) => {
			updateBusiness({ cnpj: data.data.cnpj, fantasia: data.data.fantasia });
			updateAuthId(data.data.authId);
			updateTypeConnection(data.data.typeConnection);
			updateStatusSocket('connected');
			toast.success('Conectado com sucesso! Pode iniciar as consultas!');
		});

		// socket.on('disconnect', () => {
		// 	updateStatusSocket('disconnected');
		// 	toast.info('Desconectado');
		// });

		socket.on('reconnect', () => {
			updateStatusSocket('reconnected');
		});

		return () => {
			socket.disconnect();
			socket.off('connected');
			socket.off('error');
			socket.off('connect');
			socket.off('disconnect');
			socket.off('reconnect');
		};
	}, [auth]);

	function updateStatusSocket(statusSocket: statusSocket) {
		setStatusSocket(statusSocket);
	}

	return (
		<socketIOContext.Provider
			value={{ updateStatusSocket, statusSocket, socket }}
		>
			{children}
		</socketIOContext.Provider>
	);
}

export function useSocketIO() {
	return useContext(socketIOContext);
}
