import { useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';

interface PopupInputCodeProps {
	onInput: (value: string) => void;
}
export function PopupInputCode({ onInput }: PopupInputCodeProps) {
	const [search, setSearch] = useState('');
	const [open, setOpen] = useState(false);
	return (
		<Dialog
			onOpenChange={(state) => {
				setSearch('');
				setOpen(state);
			}}
			open={open}
		>
			<DialogTrigger asChild>
				<Button className="aspect-square h-12 w-12 rounded-xl p-0">
					<FaMagnifyingGlass size={20} />
				</Button>
			</DialogTrigger>
			<DialogContent className="max-w-[375px] rounded-2xl">
				<DialogHeader>
					<DialogTitle>Pesquisar</DialogTitle>
					<DialogDescription>
						Faça a busca pelo código do produto ou código de barras.
					</DialogDescription>
				</DialogHeader>
				<div className="grid gap-4 py-4">
					<div className="flex items-center justify-center gap-4">
						<Input
							value={search}
							placeholder="789546245"
							type="number"
							onChange={(e) => setSearch(e.target.value)}
							className="col-span-3"
							onKeyUp={(e) => {
								if (e.key === 'Enter') {
									onInput(search);
									setSearch('');
									setOpen(false);
								}
							}}
						/>
						<Button
							type="submit"
							className="aspect-square px-3 py-3"
							disabled={!search}
							onClick={() => {
								if (search) {
									setSearch('');
									onInput(search);
									setOpen(false);
								}
							}}
						>
							<FaMagnifyingGlass size={20} />
						</Button>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
