import { MdOutlineLogin } from 'react-icons/md';

import { useApp } from '@/context/app';
import { toast } from 'sonner';

import { Scanner } from './Scanner';
import { Button } from './ui/button';
export function NoLogged() {
	const { updateToken } = useApp();

	function setCredentials() {
		let token: any = '';

		while (token === '') {
			token = prompt('Insira o token:');
		}

		updateToken(token);
	}

	function updateTokenByQRCode(qr: string) {
		const params = new URL(qr);
		const token = params.searchParams.get('token');
		if (token) {
			updateToken(token);
		} else {
			toast.error('QRCode Inválido');
		}
	}

	return (
		<div className="px-4 text-center">
			<h1 className="text-lg font-bold">Faça login agora</h1>
			<p>Para acessar o sistema você precisa fazer login!</p>
			<div className="mt-4 flex flex-col items-center justify-center gap-2">
				<Scanner
					onRead={updateTokenByQRCode}
					type="qrcode"
					buttonChildren="Com QRCode"
				/>
				<span>ou</span>
				<Button
					onClick={setCredentials}
					variant={'outline'}
					className="h-12 gap-2 rounded-xl"
				>
					<MdOutlineLogin size={20} />
					Com credenciais
				</Button>
			</div>
		</div>
	);
}
