import { ReactNode, useState } from 'react';
import { IoBarcodeOutline } from 'react-icons/io5';
import { MdOutlineQrCode2 } from 'react-icons/md';

import { Button } from '@/components/ui/button';
import { ReloadIcon } from '@radix-ui/react-icons';

import { PopupInputCode } from '../PopupInputCode';
import { BarcodeScanner } from './scanner';

interface scannerProps {
	loading?: boolean;
	showButton?: boolean;
	onRead: (value: string) => void;
	type?: 'barcode' | 'qrcode';
	buttonChildren?: ReactNode;
}

export function Scanner({
	showButton = true,
	onRead,
	loading = false,
	type = 'barcode',
	buttonChildren = 'Escanear',
}: scannerProps) {
	const [activeScanner, setActiveScanner] = useState(false);

	function handleClick() {
		if (showButton) {
			setActiveScanner(!activeScanner);
			// const barcodes = ['7891000325858', '7896098900208','7896020470427'];
			// onRead(barcodes[Math.floor(Math.random() * barcodes.length)]);
		}
	}

	return (
		<>
			<div className="flex items-center justify-center gap-4">
				{showButton && (
					<>
						<Button
							className="flex h-12 gap-2 rounded-xl"
							onClick={handleClick}
							disabled={loading || activeScanner}
						>
							{loading ? (
								<>
									<ReloadIcon className="animate-spin" fontSize={24} />
									Buscando
								</>
							) : activeScanner ? (
								<>
									{type === 'barcode' ? (
										<IoBarcodeOutline size={24} />
									) : (
										<MdOutlineQrCode2 size={20} />
									)}
									abrindo scanner...
								</>
							) : (
								<>
									{type === 'barcode' ? (
										<IoBarcodeOutline size={24} />
									) : (
										<MdOutlineQrCode2 size={20} />
									)}
									{buttonChildren}
								</>
							)}
						</Button>
						{type === 'barcode' && !loading && (
							<PopupInputCode onInput={onRead} />
						)}
					</>
				)}
			</div>
			<BarcodeScanner
				onRead={onRead}
				activeScanner={activeScanner}
				type={type}
				setActiveScanner={setActiveScanner}
			/>
		</>
	);
}
