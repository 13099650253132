import { IoBusiness } from 'react-icons/io5';
import { MdOutlineLogout } from 'react-icons/md';
import { TbAmpersand } from 'react-icons/tb';

import { Main } from './components/Main';
import { NoLogged } from './components/NoLogged';
import { WebAppInstallButton } from './components/WebAppInstall';
import { Button } from './components/ui/button';
import { Toaster } from './components/ui/sonner';
import { useApp } from './context/app';
import { useSocketIO } from './context/socket';
import { formatCNPJ } from './functions';

export function App() {
	const { statusSocket } = useSocketIO();
	const { connection, signOut, auth } = useApp();

	return (
		<div className="grid h-screen w-screen grid-rows-[auto_1fr_auto] bg-background">
			<header className="flex w-full items-center justify-between bg-primary px-4 py-4 text-primary-foreground">
				<h1 className="flex items-center gap-2 text-lg font-bold">
					<img src="/icons/192x192.png" alt="" className="w-7" /> Consulta preço
				</h1>
				<div className="flex flex-1 justify-end gap-1">
					<WebAppInstallButton />
					{connection.type === 'multiple' && (
						<Button
							className="px-2 pr-0 shadow-none"
							onClick={() => {
								signOut();
							}}
						>
							<MdOutlineLogout size={24} />
						</Button>
					)}
				</div>
			</header>
			<div className="relative flex flex-col items-center justify-center">
				{auth.token || statusSocket === 'connecting' ? <Main /> : <NoLogged />}
			</div>
			<Toaster />
			<footer className="mt-10 flex justify-between px-4 pb-4 md:mx-auto md:min-w-[24rem]">
				<section className="flex items-center gap-1 font-semibold">
					{auth.token && (
						<>
							<IoBusiness size={20} className="" />
							<div className="border-l border-zinc-400 pl-1">
								<p className="flex items-center gap-1 text-sm font-bold">
									{connection.business?.fantasia}
								</p>
								<p className="text-[0.75rem]">
									{formatCNPJ(connection.business?.cnpj ?? '')}
								</p>
							</div>
						</>
					)}
				</section>
				<div className="flex flex-col gap-2">
					<p className="text-[0.65rem] font-semibold leading-[0.3rem]">
						Powered by:
					</p>
					<div className="flex items-center gap-1">
						<img src="/logo-baraujo.png" alt="" className="h-6" />
						<TbAmpersand size={16} />
						<img src="/logo-etech.png" alt="" className="h-6" />
					</div>
				</div>
			</footer>
		</div>
	);
}
