import {
	ChangeEvent,
	MutableRefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import { FaXmark } from 'react-icons/fa6';
import { IoFlash } from 'react-icons/io5';
import { MdFlipCameraIos } from 'react-icons/md';

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
} from '@/components/ui/select';
import { CameraDevice, Html5Qrcode } from 'html5-qrcode';

import { Toggle } from '../ui/toggle';

interface BarcodeScannerProps {
	onRead: (value: string) => void;
	setActiveScanner: (value: boolean) => void;
	activeScanner: boolean;
	type: 'barcode' | 'qrcode';
}

const beep = new Audio('/bip-scanner.mp3');
function soundScannerSuccess() {
	beep.play();
	if (navigator && navigator.vibrate) {
		navigator.vibrate([100]);
	}
}
export function BarcodeScanner({
	onRead,
	activeScanner,
	setActiveScanner,
	type,
}: BarcodeScannerProps) {
	const [devices, setDevices] = useState<CameraDevice[]>([]);
	const [cameraActiveId, setCameraActiveId] = useState<string>('');
	const [isScanning, setIsScanning] = useState<boolean>(false);
	const scanRef = useRef<Html5Qrcode | null>(null);

	useEffect(() => {
		Html5Qrcode.getCameras()
			.then((cameras) => {
				setDevices(cameras);

				const camerasId = cameras.map((camera) => camera.id);
				const cameraDefault = localStorage.getItem('cameraActiveId');

				if (cameraDefault && camerasId.includes(cameraDefault)) {
					setCameraActiveId(cameraDefault);
				} else {
					const cameraActive = cameras.at(-1);
					if (cameraActive) {
						setCameraActiveId(cameraActive.id);
					}
				}
			})
			.catch(() => {});

		scanRef.current = new Html5Qrcode('scanner_barcode', {
			useBarCodeDetectorIfSupported: true,
			verbose: false,
		});
	}, []);

	useEffect(() => {
		if (true === activeScanner) {
			startScan();
		} else if (scanRef.current?.isScanning) {
			scanRef.current.stop().then(() => {
				scanRef.current?.clear();
				setIsScanning(false);
			});
		}
	}, [cameraActiveId, scanRef.current, activeScanner]);

	async function startScan() {
		if (cameraActiveId && scanRef.current) {
			if (scanRef.current.isScanning) {
				await scanRef.current?.stop();
				scanRef.current?.clear();
				setIsScanning(false);
			}

			scanRef.current
				.start(
					{ deviceId: cameraActiveId },
					{
						fps: 2,
						aspectRatio: window.innerHeight / window.innerWidth,
						qrbox:
							type === 'qrcode'
								? { height: 325, width: 325 }
								: { height: 150, width: 325 },
					},
					(data) => {
						onRead(data);
						soundScannerSuccess();
						scanRef.current &&
							scanRef.current.stop().then(() => {
								setIsScanning(false);
								setActiveScanner(false);
							});
					},
					() => setIsScanning(!!scanRef.current?.isScanning),
				)
				.then(() => setIsScanning(!!scanRef.current?.isScanning))
				.catch((e) => {
					console.error(e);
				});
		}
	}

	function updateCameraActiveId(id: string) {
		setCameraActiveId(id);
		localStorage.setItem('cameraActiveId', id);
		localStorage.removeItem('activeFlash');
		localStorage.removeItem('zoom');
	}

	return (
		<div
			className="fixed inset-0 z-[9998] data-[active=false]:invisible"
			data-active={isScanning && activeScanner}
		>
			{isScanning && scanRef.current?.isScanning && (
				<div className="absolute left-0 right-0 top-0 z-50 flex justify-between p-4">
					<div className="flex gap-2">
						<Select
							onValueChange={(value) => {
								updateCameraActiveId(value);
							}}
							defaultValue={cameraActiveId}
						>
							<SelectTrigger
								className="h-10 w-10 justify-center rounded-full bg-background px-1 py-1"
								showIcon={false}
							>
								<MdFlipCameraIos size={20} />
							</SelectTrigger>
							<SelectContent className="z-[9999]">
								{devices.map((device) => (
									<SelectItem key={device.id} value={device.id}>
										{device.label}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						{isScanning && scanRef.current?.isScanning && (
							<Flash scanRef={scanRef} />
						)}
					</div>
					<button
						className="inline-flex h-10 w-10 cursor-default items-center justify-center rounded-full bg-white shadow-[0_2px_10px] shadow-[#00000044] outline-none focus:shadow-[0_0_0_2px] focus:shadow-black"
						onClick={() => {
							setActiveScanner(false);
						}}
					>
						<FaXmark />
					</button>
				</div>
			)}
			<div id="scanner_barcode" />
			{isScanning && scanRef.current?.isScanning && <Zoom scanRef={scanRef} />}
		</div>
	);
}

function Zoom({ scanRef }: { scanRef: MutableRefObject<Html5Qrcode | null> }) {
	const supportZoom =
		scanRef.current &&
		scanRef.current
			.getRunningTrackCameraCapabilities()
			.zoomFeature()
			.isSupported();
	const [zoom, setZoom] = useState(0);

	useEffect(() => {
		if (!supportZoom) return;
		const zoom = localStorage.getItem('zoom');
		if (zoom) {
			setZoom(parseFloat(zoom));

			scanRef.current
				?.getRunningTrackCameraCapabilities()
				.zoomFeature()
				.apply(parseFloat(zoom.toString()));
		}
	}, []);

	function handleZoomChange(e: ChangeEvent<HTMLInputElement>) {
		const zoom = parseFloat(e.currentTarget.value);

		if (!isNaN(zoom)) {
			setZoom(zoom);
			localStorage.setItem('zoom', zoom.toString());

			scanRef.current
				?.getRunningTrackCameraCapabilities()
				.zoomFeature()
				.apply(parseFloat(e.currentTarget.value));
		}
	}

	if (!supportZoom) return null;

	return (
		<div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 translate-y-28">
			<input
				onChange={handleZoomChange}
				value={zoom}
				type="range"
				min={scanRef.current
					?.getRunningTrackCameraCapabilities()
					.zoomFeature()
					.min()}
				max={scanRef.current
					?.getRunningTrackCameraCapabilities()
					.zoomFeature()
					.max()}
				step={scanRef.current
					?.getRunningTrackCameraCapabilities()
					.zoomFeature()
					.step()}
			/>
		</div>
	);
}

function Flash({ scanRef }: { scanRef: MutableRefObject<Html5Qrcode | null> }) {
	const supportFlash =
		scanRef.current &&
		scanRef.current
			.getRunningTrackCameraCapabilities()
			.torchFeature()
			.isSupported();
	const [active, setActiveFlash] = useState(false);

	useEffect(() => {
		if (!supportFlash) return;
		const flash = localStorage.getItem('activeFlash');
		if (flash) {
			const active = flash == 'true';
			setActiveFlash(active);
			scanRef.current
				?.getRunningTrackCameraCapabilities()
				.torchFeature()
				.apply(active);
		}
	}, []);

	function handleToggleFlash(active: boolean) {
		setActiveFlash(active);
		localStorage.setItem('activeFlash', active.toString());
		scanRef.current
			?.getRunningTrackCameraCapabilities()
			.torchFeature()
			.apply(active);
	}

	if (!supportFlash) return null;

	return (
		<Toggle
			size={'lg'}
			pressed={active}
			className="h-10 w-10 rounded-full"
			onPressedChange={handleToggleFlash}
		>
			<IoFlash />
		</Toggle>
	);
}
