import { useState } from 'react';

import { useSocketIO } from '@/context/socket';

import { Product } from './Product';
import { Scanner } from './Scanner';

export function Main() {
	const [loading, setLoading] = useState(false);
	const [barcode, setBarcode] = useState('');
	const { statusSocket } = useSocketIO();

	function updateBarcode(value: string) {
		if (value[0] === '2' && value.length > 7) {
			value = value.substring(0, 7);
		}

		setBarcode(value);
	}

	return (
		<div className="mt-10 h-full w-full">
			<Product barcode={barcode} isLoading={setLoading} loading={loading} />
			<div className="mt-6 flex items-center justify-center gap-4">
				<Scanner
					onRead={updateBarcode}
					loading={loading}
					showButton={statusSocket === 'connected'}
				/>
			</div>
		</div>
	);
}
