import {
	createContext,
	ReactNode,
	useContext,
	useLayoutEffect,
	useState,
} from 'react';

interface connectionProps {
	type: 'multiple' | 'single';
	business?: businessProps;
}

interface businessProps {
	cnpj?: string;
	fantasia?: string;
}
interface authProps {
	token?: string;
}
interface appContextProps {
	updateToken: (token?: string | null) => void;
	updateAuthId: (token: string) => void;
	signOut: () => void;
	updateTypeConnection: (type: connectionProps['type']) => void;
	updateBusiness: (business: businessProps) => void;
	connection: connectionProps;
	auth: authProps;
}
const appContext = createContext<appContextProps>({} as appContextProps);

export const useApp = () => useContext(appContext);

export function AppProvider({ children }: { children: ReactNode }) {
	const [connection, setConnection] = useState<connectionProps>(
		{} as connectionProps,
	);
	const [auth, setAuth] = useState<authProps>({});

	const updateToken = (token?: string | null) => {
		if (token) {
			localStorage.setItem('token', token);
			setAuth((prev) => ({ ...prev, token }));
		} else if (token === undefined) {
			signOut();
		}
	};
	function updateAuthId(id: string) {
		const authId = localStorage.getItem('authId');

		if (!authId || authId !== id) {
			localStorage.setItem('authId', id);
		}
	}

	const updateTypeConnection = (type: 'multiple' | 'single') => {
		setConnection((prev) => ({ ...prev, type }));
		if (type === 'multiple') {
			const timeSignIn = localStorage.getItem('timeSignIn');

			if (!timeSignIn) {
				const now = new Date();
				localStorage.setItem('timeSignIn', now.toString());
			}
		}
	};

	function updateBusiness(business: any) {
		setConnection((prev) => ({ ...prev, business }));
	}

	const signOut = () => {
		localStorage.clear();
		setAuth({} as authProps);
		setConnection({} as connectionProps);
		// window.location.reload();
	};

	function verifyTimeLogged() {
		const timeSignIn = localStorage.getItem('timeSignIn');

		if (timeSignIn) {
			const savedDate = new Date(timeSignIn);
			const now = new Date();
			const differenceInMs = now.getTime() - savedDate.getTime();
			const differenceInMinutes = differenceInMs / 1000 / 60;
			const minutesMax = 10;

			if (differenceInMinutes >= minutesMax) {
				signOut();
			}
		}
	}

	useLayoutEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = urlParams.get('token');
		if (!token) {
			verifyTimeLogged();
			const token = localStorage.getItem('token');

			if (token) {
				updateToken(token);
			}
		} else {
			urlParams.delete('token');
			window.history.replaceState(
				{},
				'',
				`${window.location.pathname}?${urlParams.toString()}`,
			);
			updateToken(token || '');
		}
	}, []);

	return (
		<appContext.Provider
			value={{
				updateToken,
				auth,
				signOut,
				updateTypeConnection,
				connection,
				updateBusiness,
				updateAuthId,
			}}
		>
			{children}
		</appContext.Provider>
	);
}
